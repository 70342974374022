@import url("https://fonts.googleapis.com/css2?family=Archivo:wght@400;500;600;700&display=swap");

/* Chrome, Safari, and Opera */
.no_scroller::-webkit-scrollbar {
  width: 0;
  border-radius: 8px;
  background-color: transparent;
}

.no_scroller::-webkit-scrollbar-thumb {
  background-color: transparent;
}
